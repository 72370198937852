import React from "react"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"

import Logo from "@assets/icons/logo.svg"

import "./header.css"

const StyledHeader = styled.header`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};
  color: ${props => (props.foreground ? props.foreground : "black")};
  background-color: ${props => (props.background ? props.background : "white")};
`

const Header = props => (
  <ThemeContext.Consumer>
    {theme => {
      return (
        <StyledHeader
          foreground={theme.foreground}
          accent={theme.accent}
          background={theme.background}
        >
          <Link to="/" className="link--home">
            <Logo className="logo"></Logo>
          </Link>
        </StyledHeader>
      )
    }}
  </ThemeContext.Consumer>
)

export default Header
