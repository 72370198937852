import React from "react"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"

const StyledFooter = styled.footer`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};

  color: ${props => (props.foreground ? props.foreground : "black")};
  background-color: ${props => (props.background ? props.background : "white")};
  transition: background-color 0.5s, color 0.5s;
`

const Footer = props => (
  <ThemeContext.Consumer>
    {theme => {
      return (
        <StyledFooter
          foreground={theme.foreground}
          accent={theme.accent}
          background={theme.background}
        >
          <div className="layout-container">
            <Link to="/impressum" className="link link--contact">
              Impressum
            </Link>
            <Link to="/datenschutz" className="link link--contact">
              Datenschutz
            </Link>
          </div>
        </StyledFooter>
      )
    }}
  </ThemeContext.Consumer>
)

export default Footer
